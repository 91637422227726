<template>
  <div class="QuickStart document-content">
    <div class="docHeader">
      <h2>快速开始</h2>
      <div class="updateTime">发布时间：2022-01-21 9:00</div>
    </div>
  </div>
</template>

<script setup>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.QuickStart {
}
</style>